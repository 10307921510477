import React from 'react';

export const OrderCreated = props => {

    return (
        <div className="information__box">
            <span className="information__img"><i className="fas fa-calendar"></i></span>
            <span className="information__text">
                Fecha de compra: <br className="information__br" />
                <span className="purchase-traking__insert-info">{props.date}</span>
            </span>
        </div>
    )
}