import React, { Fragment } from "react";
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';


export const TrackingState = (props) => {

  return (
    <Fragment>        
      <p className={!props.status_history.completed && 'text-muted'}>
        <span className="mr-2">{props.status_history.completed ? <FaRegCheckCircle /> : <FaRegCircle /> }</span>
        {props.status_history.state} <small className="font-italic">{props.status_history.date} {props.status_history.time}</small>
      </p>
    </Fragment>
  );
};

