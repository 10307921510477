import React, { useState } from 'react';
import { Form, Button, Alert } from './styles';
import { Redirect } from 'react-router-dom';
import { getTracking } from "../../services/tracking.api";
import { Spinner } from '../../components/common/Spinner';


export const FormLogin = props => {


    const [form, setForm] = useState({ external_reference:'', email: ''});
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const handlerOnChange = ({ target }) => {
        const { name, value } = target;
        setForm({
            ...form,
            [name]: value
        })
    }


    const handleOnSubmit = event => {
        event.preventDefault();
        setLoading(true);
        getTracking(form).then(
            (response) => {
                console.log('login response ->', response);
                setLoading(false);
                setError(false);
                setRedirect(true);   
            },
            (_error) => {
                setLoading(false);
                setError(true);
                console.log('error: ', _error);
            }
        );
        
    }

    if (redirect) {
        const base64 = btoa(JSON.stringify(form));
        return <Redirect to={`/tracking/${base64}`} />
    }

    return (
        <React.Fragment>
                <Form onSubmit={handleOnSubmit} method="POST">
                    {
                        error && 
                        <Alert variant="warning" >
                            La orden no fue encontrada
                        </Alert>
                    }
                    <Form.Group controlId="OrderCustomer">
                        <Form.Label>Orden: </Form.Label>
                        <Form.Control type="text" name="external_reference" onChange={handlerOnChange} required />
                    </Form.Group>
                    <Form.Group controlId="OrderEmail">
                        <Form.Label>Email: </Form.Label>
                        <Form.Control type="email" name="email" onChange={handlerOnChange} required />
                    </Form.Group>

                    <Button variant="success" type="submit" disabled={loading}>
                    {loading ? <Spinner size="xs" bg="white" />:"Consultar"}
                    </Button>
                </Form>
        </React.Fragment>
    )
}