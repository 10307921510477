import React, { Fragment } from 'react';

export const TrackingHeader = ({tracking}) => {

    return (
        <Fragment>
        <h2 className="traking__title">
            Te informamos que:
        </h2>
        <p className="mb-5">
            {/* Tu compra <span className="purchase-traking__insert-info-2"><strong>{tracking.number}</strong></span> será despachada a {tracking.shippingAddress} y el plazo de entrega programado es: <strong>{tracking.maximumDeliveryTime}</strong> */}
            {tracking.info}
        </p>

        </Fragment>
    )
}