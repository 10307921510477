import axios from 'axios';

export const config = {
    apiUrl: process.env.REACT_APP_BASE_API_URL,
    token: 'aaae9aae-58f7-4e1e-8ac9-a90bd5ae3e4b'
}


export const getTracking = async (data) => {
    
    const url = `${config.apiUrl}/api/v2/tracking/order/${data.external_reference}/states?email=${data.email}`;
    const requestOptions = {
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'application/json' }
    }

    return await axios.get(url, requestOptions)
}
